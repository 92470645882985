import domReady from '@wordpress/dom-ready';

const eventCalendar = document.querySelector('.featured-events__calendar');

function toggleCalendar() {
  if (!eventCalendar) {
    return;
  }

  const toggleCalendarBtn = document.querySelector('#toggleCalendarBtn');

  if (toggleCalendarBtn) {
    toggleCalendarBtn.addEventListener('click', function (e) {
      e.preventDefault();
      toggleCalendarBtn.classList.add('hidden-btn');
      eventCalendar.classList.remove('hidden-calendar');
    });

    if (toggleCalendarBtn.classList.contains('hidden-btn')) {
      eventCalendar.classList.remove('hidden-calendar');
    }
  }
}

domReady(() => {
  if (eventCalendar) {
    if (window.innerWidth < 769) {
      eventCalendar.classList.add('hidden-calendar');
    } else {
      eventCalendar.classList.remove('hidden-calendar');
    }

    toggleCalendar();

    $(window).on('resize', function () {
      toggleCalendar();
    });
  }
});
